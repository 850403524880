import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {IntranetService} from '../services/intranet.service';
import {AccountInfo} from '../models/CurrentViewInfo';
import {SharedDataService} from '../services/shared-data.service';

@Component({
    selector: 'app-min-max',
    templateUrl: './min-max.component.html',
    styleUrls: ['./min-max.component.scss'],
})
export class MinMaxComponent implements OnInit, OnDestroy {

    minInfo = '10';
    maxInfo = '100';
    @Output() changeshowMinMax = new EventEmitter();


    constructor(private intranetService: IntranetService, private sd: SharedDataService) {
    }

    ngOnInit() {
        this.minInfo = '50';
        this.maxInfo = '5000';
     }

    ngOnDestroy(): void {
    }

    closeSelf() {
        this.changeshowMinMax.emit(false);
    }
}
