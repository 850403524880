import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AccountInfo} from '../../models/CurrentViewInfo';
import {IntranetService} from '../../services/intranet.service';
import {Constants} from '../../MarketOddsModel/constants';
import {timer} from 'rxjs';
import {SharedDataService} from '../../services/shared-data.service';

@Component({
    selector: 'app-account-overview',
    templateUrl: './account-overview.component.html',
    styleUrls: ['./account-overview.component.scss'],
})
export class AccountOverviewComponent implements OnInit, OnDestroy {

    overviewShown = false;
    accountInfo: AccountInfo;
    C = Constants.glob;
    overviewClass: string;
    balance: {};
    timer;

    constructor(private intranetService: IntranetService, private changeDetector: ChangeDetectorRef, private  sd: SharedDataService) {
        this.accountInfo = new AccountInfo();
    }

    ngOnInit() {
        this.accountInfo = new AccountInfo();
        this.timer = setInterval(() => {
            this.sd.getBalance();
        }, 10000);
        this.subscribeToBalance();
        this.changeDetector.detectChanges();
    }

    roundToTwo(num) {
        return num.toFixed(2);
    }

    getBalance() {
        // this.intranetService.getBalance().subscribe(
        //     data => {
        //         this.accountInfo = data.result;
        //         this.changeDetector.detectChanges();
        //     },
        //     error => {
        //         console.log(error);
        //     }
        // );
    }

    toggle() {
        this.overviewShown = !this.overviewShown;
        this.overviewClass = this.overviewShown ? 'shown -animate' : 'hidden';
    }

    ngOnDestroy(): void {
        clearInterval(this.timer);
    }

    private subscribeToBalance() {
        let maxRetry = 0;
        const timeout = setInterval(() => {
            if (this.sd.balance !== undefined) {
                clearInterval(timeout);
                this.sd.balance.subscribe((data) => {
                    this.accountInfo.balance = data.result.balance;
                    this.accountInfo.exposure = data.result.exposure;
                });
            } else if (maxRetry > 5000) {
                clearInterval(timeout);
            } else {
                maxRetry = maxRetry + 100;
            }
        }, 100);
    }
}
