import {Injectable} from '@angular/core';
import {AccountInfo} from '../models/CurrentViewInfo';
import {BehaviorSubject, Observable} from 'rxjs';
import {IntranetService} from './intranet.service';
import {map} from 'rxjs/operators';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { WalletInfoResponse } from '../models/wallet-info.interface';

@Injectable({
    providedIn: 'root'
})
export class SharedDataService {

    private data = [];
    public balanceSubject: BehaviorSubject<WalletInfoResponse>;
    public balance: Observable<WalletInfoResponse>;
    private accountInfo = new AccountInfo();

    public gameDataSubject: BehaviorSubject<any>;
    public gameData: Observable<any>;

    public operatorDataSubject: BehaviorSubject<any>;
    public operatorData: Observable<any>;

    public stakesDataSubject: BehaviorSubject<any>;
    public stakeData: Observable<any>;

    constructor(private intranetService: IntranetService, private http: HttpClient, private router: Router) {
        this.initBalance();
        this.initGames();
        this.initStakes();
        // this.initOperator();
    }

    setData(id, data) {
        this.data[id] = data;
    }

    getData = (id) => {
        return this.data[id];
    }

    initBalance() {

        this.intranetService.getBalance().subscribe((value) => {
            this.balanceSubject = new BehaviorSubject<any>(value);
            this.balance = this.balanceSubject.asObservable();
        });
    }

    getBalance() {

        this.intranetService.getBalance().subscribe((value) => {
            this.balanceSubject && this.balanceSubject.next(value);
        });
    }

    initGames() {
        this.intranetService.getGames().subscribe((value => {
            this.gameDataSubject = new BehaviorSubject<any>(value);
            this.gameData = this.gameDataSubject.asObservable();
        }));
    }

    initOperator() {
        this.intranetService.getOperator().subscribe((value => {
            this.operatorDataSubject = new BehaviorSubject<any>(value);
            this.operatorData = this.operatorDataSubject.asObservable();
        }));
    }

    getGames() {
        this.intranetService.getGames().subscribe((value => {
            this.gameDataSubject.next(value);
        }));
    }


    private initStakes() {
        this.intranetService.getDynamicStakes().subscribe((value) => {
            this.stakesDataSubject = new BehaviorSubject<any>(value);
            this.stakeData = this.stakesDataSubject.asObservable();
        });
    }

    getStakes() {
        this.intranetService.getDynamicStakes().subscribe((value) => {
            this.stakesDataSubject.next(value);
        });
    }
}
