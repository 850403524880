import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {IntranetService} from '../services/intranet.service';
import {AccountInfo} from '../models/CurrentViewInfo';
import {SharedDataService} from '../services/shared-data.service';

@Component({
    selector: 'app-right-menu',
    templateUrl: './right-menu.component.html',
    styleUrls: ['./right-menu.component.scss'],
})
export class RightMenuComponent implements OnInit, OnDestroy {

    accountInfo: AccountInfo;
    timer;
    @Output() changeshowBetBox = new EventEmitter();


    constructor(private intranetService: IntranetService, private sd: SharedDataService) {
        this.accountInfo = new AccountInfo();
    }

    ngOnInit() {
        // this.sd.initBalance();
        this.sd.balance.subscribe((data) => {
            this.accountInfo.balance = data.result.balance;
            this.accountInfo.exposure = data.result.exposure;
        });
        // this.getBalance();
        // this.timer = setInterval(() => {
        //     this.sd.initBalance();
        // }, 5000);
    }

    ngOnDestroy(): void {
        clearInterval(this.timer);
    }

    closeSelf() {
        this.changeshowBetBox.emit(false);
    }

    // getBalance() {
    //     this.intranetService.getBalance().subscribe(
    //         data => {
    //             this.accountInfo = data.result;
    //             if (this.accountInfo.exposure === null) {
    //                 this.accountInfo.exposure = 0;
    //             }
    //             if (this.accountInfo.balance === null) {
    //                 this.accountInfo.balance = 0;
    //             }
    //         },
    //         error => {
    //             console.log(error);
    //         }
    //     );
    // }
}
