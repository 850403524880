export class CurrentViewInfo {
  isEventView: boolean;
  showBetsByEvent: boolean;
}

export class Status {
  avgOdds = false;
  orderByDate = false;
}

export class AccountInfo {
  balance = 0;
  exposure = 0;
  totalBalance = 0;
}

export class MenuLvl {
  upcomingLvlNr: number;
  sportRoot: MenuLvl;
  children: [MenuLvl];
  id: number;
  parent: any;
  type: string;
  isRootCategory: boolean;
  sortOrder: number;
  name: string;
  isClosed: boolean;
}

export class SportRoot {
  name: string;
}

export class Visibility {
  newBets = false;
  existingBets = false;
}
