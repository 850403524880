import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BetslipComponent} from './betslip/betslip.component';
import {HeaderComponent} from './component/header/header.component';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {SharedModule} from './shared.module';
import { ScrollToModule } from 'ng2-scroll-to-el';
import {MatButtonModule} from '@angular/material/button';
// import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';


@NgModule({
    declarations: [AppComponent, BetslipComponent, HeaderComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        SharedModule,
        BrowserAnimationsModule,
        MatButtonModule,
        ScrollToModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
        }),
        // NgxGoogleAnalyticsModule.forRoot('GTM-K7DG45PH')
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
