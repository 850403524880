import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { DataResolverService } from "./services/data-resolver.service";

const routes: Routes = [
  // {
  //   path: "",
  //   redirectTo: "/teenpatti",
  //   pathMatch: "full",
  // },
  // {
  //     path: 'home',
  //     loadChildren: './home/home.module#HomePageModule'
  // },
  // {
  //     path: 'list',
  //     loadChildren: './list/list.module#ListPageModule'
  // },
  // {
  //     path: 'splash-screen/:token/:opratorId',
  //     loadChildren: './splash-screen/splash-screen.module#SplashScreenPageModule'
  // },
  {
    path: "splash-screen/:token/:opratorId",
    loadChildren: "./splash-screen/splash-screen.module#SplashScreenPageModule",
  },
  {
    path: "error401",
    loadChildren: "./helpers/error401/error401.module#Error401PageModule",
  },

  //{path: 'bettingpandl', loadChildren: './MarketRunnerComponents/bettingpandl/bettingpandl.component.ts#BettingpandlComponent'},

  // { path: 'splash-screen', loadChildren: './splash-screen/splash-screen.module#SplashScreenPageModule' }
  // {path: 'login', loadChildren: './login/login.module#LoginPageModule'},
  {
    path: "teenpatti",
    loadChildren: "./teenpatti/teenpatti.module#TeenpattiPageModule",
  },
  // {path: 'teenpatti-event', loadChildren: './teenpatti-event/teenpatti-event.module#TeenpattiEventPageModule'},
  {
    path: "teenpattievent-info/:id",
    resolve: {
      passedData: DataResolverService,
    },
    loadChildren:
      "./teenpattievent-info/teenpattievent-info.module#TeenpattieventInfoPageModule",
  },
  // {path: 'news', loadChildren: './news/news.module#NewsPageModule'},
  // {path: 'open-bets', loadChildren: './open-bets/open-bets.module#OpenBetsPageModule'},
  {
    path: "setting",
    loadChildren: "./setting/setting.module#SettingPageModule",
  },
  {
    path: "open-bets2",
    loadChildren: "./open-bets2/open-bets2.module#OpenBets2PageModule",
  },
  {
    path: "betting-profit-loss",
    loadChildren:
      "./betting-profit-loss/betting-profit-loss.module#BettingProfitLossPageModule",
  },
  // {
  //     path: 'transfer-statement',
  //     loadChildren: './transfer-statement/transfer-statement.module#TransferStatementPageModule'
  // },
  // {path: 'change-password', loadChildren: './change-password/change-password.module#ChangePasswordPageModule'},
  {
    path: "rules-regulations",
    loadChildren:
      "./rules-regulations/rules-regulations.module#RulesRegulationsPageModule",
  },
  // {path: 'time-setting', loadChildren: './time-setting/time-setting.module#TimeSettingPageModule'},
  // {path: 'live', loadChildren: './live/live.module#LivePageModule'},
  // { path: 'splash-screen', loadChildren: './splash-screen/splash-screen.module#SplashScreenPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
