export class Constants {
  public static API_ENDPOINT = 'http://127.0.0.1:6666/api/';
  public static glob = {
    EMPTY: 'Please fill all fields.',
    ERR_UI_00: 'Sorry, some of the submitted data was wrong. Please check your input and try again.',
    ERR_UI_01: 'Username is required.',
    ERR_UI_02: 'Password is required.',
    ERR_UI_03: 'Captcha is required.',
    ERR_UI_04: 'Captcha must be must be 4 character(s).',
    ERR_UI_05: 'Please fill username field.',
    ERR_UI_06: 'Password should be between 6 and 15 characters.',
    ERR_UI_07: 'Passwords mismatch.',
    ERR_UI_08: 'Please fill contact number.',
    ERR_UI_09: 'Please choose user status.',
    ERR_UI_10: 'Field is empty.',
    ERR_UI_11: 'Value should be less or equal to {{ value }}.',
    ERR_UI_12: 'Value should be more or equal to {{ value }}.',
    ERR_UI_13: 'Please specify your current password.',
    ERR_UI_14: 'Old Password is required.',
    ERR_UI_15: '\'To\' date must be greater than \'From\' date.',
    ERR_UI_16: '\'From\' date must not be greater than today\'s date.',
    ERR_UI_17: '\'To\' date must not be greater than today\'s date.',
    RECAPTCHA_ERROR: 'Communication error! Please check your network connection and try again.',
    LOADER_IN_PROGRESS: 'Loading...',
    PLACING_BET: 'Bet placement in progress',
    PLACING_THIS_BET: 'Placing this bet',
    PLACE_BET_SUCCESS: 'Bet{{ suffix }} successfully placed.',
    UPDATE_BET_SUCCESS: 'Bet{{ suffix }} successfully updated.',
    REMOVING_THIS_BET: 'Removing this bet',
    GENERAL_PLACING_BET_ERR: 'Bet{{ suffix }} failed.',
    GENERAL_UPDATING_BET_ERR: 'Update failed.',
    GENERAL_CANCEL_BET_ERR: 'Cancel failed.',
    WELCOME: 'Welcome back',
    SETTINGS: 'Settings',
    ACCOUNT: 'Account',
    LOGGED_IN_AS: 'Logged in as ',
    LAST_LOGGED_IN: 'Last logged in: ',
    LOGOUT: 'Logout',
    TIMEZONE_OPTION_BROWSER: 'Your computer time',
    TIMEZONE_OPTION_SERVER: 'System time',
    TIMEZONE_OPTION_INDIA: 'India Standard time',
    CONFIRM_BET: 'Are you sure you want to place your bet?',
    CONFIRM_BETS: 'Are you sure you want to place your bets?',
    CONFIRM_REMOVE_BETS: 'Are you sure you want to cancel all your bets from this market?',
    CONFIRM_REMOVE_ALL_BETS: 'Are you sure you want to cancel ALL your bets?',
    UNMATCHED_BETS_CANCELED_SUCCESS: '{{ value }} bet{{ suffix }} cancelled successfully!',
    UNMATCHED_BETS_CANCELED_FAILURE: '{{ value_fail }} bet{{ suffix }} failed to cancel',
    BET_SLIP_TITLE: 'Your Bets',
    BET_RECEIPT_TITLE: 'Your Bets Receipt',
    CONFIRM_UPDATE_ALL_BETS: 'Are you sure you want to update these bets?',
    CONFIRM_UPDATE_SINGLE_BET: 'Are you sure you want to update your bet?',
    ONECLICK_CONFIRM_BETSLIP_CLEAR: 'All bets in your betslip will be cleared. Is this ok?',
    TITLE_FAVOURITES: 'Favourites',
    CONFIRM_REMOVE_FAVOURITE: 'Are you sure you want to remove it from favourite?',
    BETSLIP: 'Betslip',
    MATCHED_BETS: 'Matched Bets',
    CONSOLIDATE: 'Consolidate',
    AVGODDS: 'Average Odds',
    ORDERBYDATE: 'Order By Date',
    UNMATCHED_BETS: 'Unmatched Bets',
    OPEN_BETS: 'Open Bets',
    ODDS: 'Odds',
    ODDS_H_J: 'Odds (H-J)',
    STAKE: 'Stake',
    PROFIT: 'Profit',
    LIABILITY: 'Liability',
    PROFIT_LIABILITY: 'Profit/Liability',
    REF: 'Ref',
    SUBMITTED: 'Submitted',
    RESET: 'Reset',
    CANCEL_ALL: 'Cancel All',
    CANCEL_ALL_IN_MARKET: 'Cancel All Bets in Market',
    UPDATE_ALL: 'Update All',
    CLEAR: 'Clear',
    EDIT_STAKES: 'Edit Stakes',
    SHOW_BETS_BY_EVENT: 'Show bets for this event',
    SHOW_BETS_INFO: 'Show bet Info',
    REFRESH: 'Refresh',
    LABEL_NO_UNMATCHED_BETS: 'You have no unmatched bets',
    LABEL_NO_UNMATCHED_BETS_BY_EVENT: 'You have no unmatched bets for this event',
    LABEL_NO_MATCHED_BETS: 'You have no matched bets',
    LABEL_NO_MATCHED_BETS_BY_EVENT: 'You have no matched bets for this event',
    BET_SETTLED: 'Settled',
    MENU_PREVIOUS: 'Previous',
    MENU_HOME: 'Sports',
    MENU_FAVOURITES: 'Favourites',
    MENU_ACCOUNT_STATEMENT: 'Account Statement',
    MENU_TRANSFER_STATEMENT: 'Transfer Statement',
    AS_DATE: 'Date',
    AS_DESCRIPTION: 'Description',
    AS_PNL: 'P&L',
    AS_CREDIT_LIMIT: 'Credit Limit',
    AS_BALANCE: 'Balance',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    TRANSFER_DATE: 'Date',
    TRANSFER_DESCRIPTION: 'Description',
    TRANSFER_AMNT: 'Amount',
    TRANSFER_OUT: 'Transfer Out',
    TRANSFER_IN: 'Transfer In',
    NO_TRANSFER_DATA_IN_RANGE: 'There have been no transfers in the last 180 days.',
    ORDER_LIST_TITLE: 'My Bets',
    ORDER_LIST_HD_SETTLED: 'Settled',
    ORDER_LIST_HD_PLACED: 'Placed',
    ORDER_LIST_HD_DESCRIPTION: 'Description',
    ORDER_LIST_HD_TYPE: 'Type',
    ORDER_LIST_HD_ODDS: 'Odds',
    ORDER_LIST_HD_STAKE: 'Stake',
    ORDER_LIST_HD_LIABILITY: 'Liability',
    ORDER_LIST_HD_PROFIT: 'Potential Profit',
    ORDER_LIST_HD_PROFIT_LOSS: 'Profit/Loss',
    ORDER_LIST_HD_STATUS: 'Status',
    ORDER_LIST_LABEL_PLACED_DATE: 'Placed',
    ORDER_LIST_LABEL_MATCHED_DATE: 'Matched',
    ORDER_LIST_LABEL_BET_ID: 'Bet ID',
    ORDER_LIST_LABEL_CURRENT: 'Current',
    ORDER_LIST_LABEL_PAST: 'Past',
    ORDER_LIST_LABEL_MATCHED: 'Matched',
    ORDER_LIST_LABEL_UNMATCHED: 'Unmatched',
    ORDER_LIST_ERROR_GENERIC: 'Failed to retrieve your bets.',
    BET_TYPE_BACK: 'Back',
    BET_TYPE_LAY: 'Lay',
    BET_TYPE_NO: 'No',
    BET_TYPE_YES: 'Yes',
    RUNS: 'Runs',
    GAMES: 'Games',
    LABEL_DESCRIPTION: 'Description',
    LABEL_MARKET: 'Market',
    LABEL_TYPE: 'Type',
    LABEL_PROFIT_LOSS: 'Profit/Loss',
    LABEL_STATUS: 'Status',
    LABEL_PLACED: 'Placed',
    LABEL_START_TIME: 'Start time',
    LABEL_SETTLED_TIME: 'Settled time',
    LABEL_BET_ID: 'Bet ID',
    LABEL_TOTAL: 'Total P&L',
    LABEL_MATCH: 'Match',
    LABEL_BET_MANAGER: 'Betslip',
    LABEL_NET_WIN: 'Net Win',
    LABEL_COMMISSION: 'Comm.',
    LABEL_SELECTION: 'Selection',
    LABEL_BACK_SUBTOTAL: 'Back subtotal',
    LABEL_LAY_SUBTOTAL: 'Lay subtotal',
    LABEL_YES_SUBTOTAL: 'Yes subtotal',
    LABEL_NO_SUBTOTAL: 'No subtotal',
    LABEL_MARKET_SUBTOTAL: 'Market subtotal',
    LABEL_MARKET_COMMISSION: 'Commission',
    LABEL_NET_MARKET_TOTAL: 'Net Market Total',
    LABEL_BETTING_PROFIT_LOSS: 'Betting Profit and Loss',
    STAKES_UPDATE_SUCCESS: 'Stakes updated Successfully',
    BETLIST_REF: 'Ref:',
    BETLIST_PLACED: 'Placed:',
    PLACE_BET: 'Place bets',
    REMOVE_ALL: 'Remove All',
    CONFIRM_BET_PLACEMENT_SETTINGS: 'Confirm bets before placing',
    CONFIRMATION_SETTING_SUCCESS: 'Confirmation setting saved successfully',
    CONFIRMATION_SETTING_ERROR: 'Confirmation setting has not been changed!',
    PNL_TITLE: 'Betting Profit and Loss',
    PNL_ERROR_GENERIC: 'Failed to retrieve your betting profit and loss.',
    PNL_BREAKDOWN_ERROR_GENERIC: 'Failed to retrieve your betting profit and loss breakdown.',
    PASSWORD_CHANGED: 'Password changed successfully',
    MENU_CHANGE_PASSWORD: 'Change Password',
    USER_FORM_USERNAME: 'Username',
    USER_FORM_PASSWORD: 'Password',
    USER_FORM_NEW_PASSWORD: 'New Password',
    USER_FORM_OLD_PASSWORD: 'Old Password',
    USER_FORM_REPEAT_PASSWORD: 'Repeat Password',
    USER_FORM_SAVE: 'Save',
    SEARCH_BAR_PLACEHOLDER: 'Search Events',
    SEARCH_NO_EVENTS_FOUND: 'No events found matching',
    ONE_CLICK_TITLE: '1 Click Betting',
    ONE_CLICK_SAVE: 'Save',
    ONE_CLICK_EDIT: 'Edit',
    ACC_OVERVIEW_CREDIT_LIMIT: 'Credit Limit',
    ACC_OVERVIEW_WINNINGS: 'Winnings',
    ACC_OVERVIEW_TOTAL_NET_EXPOSURE: 'Total Net Exposure',
    ACC_OVERVIEW_UNMATCHED_BETS: 'Unmatched Bets',
    ACC_OVERVIEW_AVAILABLE_CREDIT: 'Available Credit',
    RULES_TITLE: 'Rules & Regulations',
    NO_MARKET_DATA: 'No market data available',
    INVALID: 'Invalid',
    REDUCED: 'reduced',
    REDUCTION_FACTOR_APPLIED: 'Reduction factor applied',
    NEXT_RACE: 'Next Race:',
    IN_PLAY: 'In-Play',
    RACE_ODDS: 'Race Odds',
    REMOVED: 'Removed',
    NON_RUNNER: 'Non Runner',
    TRAINER: 'Trainer:',
    AGE_WEIGHT: 'Age/Weight:',
    FORM: 'Form:',
    DAYS_SINCE_LAST_RUN: 'Days since last run:',
    JOCKEY_CLAIM: 'Jockey claim:',
    WEARING: 'Wearing:',
    UPCOMING_RACES: 'Upcoming Races',
    TOP_OF_RUNNERS: 'Top {{favourite}} of {{runners}} runners',
    CLOTH_NUMBER: 'Saddlecloth Number',
    STALL_DRAW: 'Stall Draw',
    OPENS_IN: 'Opens in {{timeDifference}}',
    COUNT_DOWN_DAY: 'day',
    COUNT_DOWN_DAYS: 'days',
    COUNT_DOWN_HOUR: 'hr',
    COUNT_DOWN_HOURS: 'hrs',
    COUNT_DOWN_MINUTE: 'min',
    COUNT_DOWN_MINUTES: 'mins',
    WINNER: 'Winner',
    SPORT_BETTING_ENABLED_IN: 'Betting from {{ timeToStart }} before start',
    SESSION_RUNS: 'Session Runs',
    BALL_RUNNING: 'Ball Running',
    SUSPENDED: 'SUSPENDED',
    MAX_LIABILITY: 'Max Bet:',
    MAX_LIABILITY_PER_MKT: 'Max Mkt:',
    LADDER_GAMES_TITLE: 'Games',
    LADDER_RUNS_TITLE: 'Runs',
    LADDER_PNL_TITLE: 'P&L',
    matchStats: {
      requiredScoreMessage: '{{team}} need {{requiredScore}} runs',
      requiredRunsFromBallsMessage: ' from {{remainingBalls}} balls',
      teamWonMessage: 'Match won by {{team}}',
      oversAdjusted: ' - reduced to {{oversNum}} overs',
      battingBowling: {
        title: 'Stats',
        batsmen: 'Batsmen',
        R: 'R',
        B: 'B',
        '4s': '4s',
        '6s': '6s',
        SR: 'SR',
        bowler: 'Bowler',
        O: 'O',
        M: 'M',
        W: 'W',
        ECO: 'ECO'
      },
      overs: 'Overs',
      runRate: 'CRR',
      partnership: 'P\'SHIP',
      projScr: 'PROJ SCR',
      requiredRunRate: 'RRR',
      lastWicket: 'LAST WKT',
      optToBowl: 'opt to bowl',
      optToBat: 'opt to bat',
      BetweenInnings: 'Innings Break',
      Review: 'REVIEW',
      Drinks: 'Drinks',
      Rain: 'Rain',
      BadLight: 'Bad light stopped play',
      Injury: 'Injury timeout',
      Other: 'Delay',
      InPlay: 'In play',
      oversSummary: {over: 'Ov', runs: ' runs', run: ' run', to: ' to ', and: ' and '},
      accordion: {closeTitle: 'Hide Cricket Match Centre', openTitle: 'Show Cricket Match Centre'}
    },
    betslip: {
      selectionSuffixes: {
        toQualify: ' - To Qualify',
        completedMatch: ' - Completed Match',
        tiedMatch: ' - Tied Match',
        halfTime: ' - Half Time',
        cornerOdds: ' - Corners Odds',
        cornerMatchBet: ' - Corners Match Bet',
        bookingOdds: ' - Bookings Odds',
        bookingMatchBet: ' - Bookings Match Bet',
        bothTeamsToScore: ' - Both Teams To Score?',
        sendingOff: ' - Sending Off?'
      }
    },
    appVersionChecker: {outdatedMessage: 'It seems you are using an outdated version. Please ', refresh: 'refresh'},
    sessionTimeout: {
      timeoutMessage: 'You have been inactive for a long time, your session is about to expire.',
      renewSessionButton: 'Click to renew session'
    },
    MARKET: 'Market'
  };

}



