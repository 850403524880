import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-betslip',
  templateUrl: './betslip.component.html',
  styleUrls: ['./betslip.component.scss'],
})
export class BetslipComponent implements OnInit {
  p1Div: boolean;
  p2Div: boolean;
  divName: any;

  constructor() { }

  ngOnInit() {}
}
