import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {OrderBet} from '../models/OrderBet';
import {Router} from '@angular/router';
import {AccountInfo} from '../models/CurrentViewInfo';
import {AuthenticationService} from './authentication.service';
import { WalletInfoResponse } from '../models/wallet-info.interface';
import { BetLimitResponse, MinMaxResult } from '../models/bet-limit.interface';


@Injectable({
    providedIn: 'root'
})

export class IntranetService {

    host: string;
    public balance: Observable<AccountInfo> = new Observable<AccountInfo>();
    public orderBet: OrderBet;

    specialParam: string; //added for updating the special param in intranet service which is used in teenpattievent-info.page.ts file

    constructor(private http: HttpClient, private router: Router, private authenticationService: AuthenticationService) {
        this.host = authenticationService.host;
    }

    public checkToken(chk) {
        if (chk == null) {
            this.router.navigate(['/error401']);
            return;
        } else {
            return chk.token;
        }
    }

    public getHeaders() {
        // @ts-ignore
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'X-access-token': this.checkToken(JSON.parse(sessionStorage.getItem('currentUser')))
        });
    }

    getGames() {
        const currentUser = JSON.parse(sessionStorage.getItem('userInfo'));
        return this.http.get<any>(this.host + '/games/operator/' + (currentUser !== undefined ? currentUser.operatorId : '9092'), {
            headers: this.getHeaders()
        }).pipe(map(games => {
            return games;
        }));
    }

    getOperator() {
        const currentUser = JSON.parse(sessionStorage.getItem('userInfo'));
        return this.http.get<any>(this.host + '/operator/opDetails/' + (currentUser !== undefined ? currentUser.operatorId : '9092'), {
            headers: this.getHeaders()
        }).pipe(map(opdetails => {
            return opdetails;
        }));
    }

    getGameDetails(id) {
        return this.http.get<any>(this.host + '/games/secure/' + id, {
            headers: this.getHeaders()
        }).pipe(map(gamedetails => {
            // login successful if there's a jwt token in the response
            return gamedetails;
        }));
    }

    getGameDetailsForStream(id) {
        return this.http.get<any>(this.host + '/games/secure/' + id, {
            headers: this.getHeaders()
        }).pipe(map(gameDetailsForStream => {
            // login successful if there's a jwt token in the response
            return gameDetailsForStream;
        }));
    }

    editGame(gameId, gameObj) {
        return this.http.put<any>(this.host + '/games/' + gameId, gameObj, {
            headers: this.getHeaders()
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getMarketDetails(gameId) {
        return this.http.post<any>(this.host + '/api/exchange/odds/', {gameId}, {
            headers: this.getHeaders()
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getUserOdds(gameId) {
        return this.http.get<any>(this.host + '/api/exchange/odds/' + gameId, {
            headers: this.getHeaders()
        }).pipe(map(userOdds => {
            return userOdds;
        }));
    }

    getCards(gameId) {
        return this.http.get<any>(this.host + '/api/exchange/odds/cards/' + gameId, {
            headers: this.getHeaders()
        }).pipe(map(userOdds => {
            return userOdds;
        }));
    }

    updateCards(cardDetails) {
        return this.http.post<any>(this.host + '/api/exchange/odds/updatecards', cardDetails, {
            headers: this.getHeaders()
        })
            .pipe(map(data => {
                return data;
            }));
    }


    placeOrder(order: OrderBet) {
        // return order;
        return this.http.post<any>(this.host + '/order/', order, {
            headers: this.getHeaders()
        })
            .pipe(map(data => {
                return data;
            }));
    }

    getOpenBets(gameId, userId) {
        // http://192.168.1.28:1234
        return this.http.get<any>(this.host + '/order/' + gameId + '/' + userId, {
            headers: this.getHeaders()
        }).pipe(map(bets => {
            return bets;
        }));
    }

    last_result(gameId) {
        // http://192.168.1.28:1234
        return this.http.get<any>(this.host + '/result/past_result?gameId=' + gameId, {
            headers: this.getHeaders()
        }).pipe(map(bets => {
            return bets;
        }));
    }

    getBettingProfitAndLoss(data) {
        return this.http.post<any>(this.host + '/profitloss/member/account/pnl/', data,
            {
                headers: this.getHeaders()
            }).pipe(map(profitLoss => {
            return profitLoss;
        }));
    }

    getRoundProfitAndLoss(data) {
        return this.http.post<any>(this.host + '/profitloss/round/', data,
            {
              headers: this.getHeaders()
            }).pipe(map(profitLossRecords => {
            return profitLossRecords;
        }));
    }

    authentication(token, opratorId) {
        return this.http.post<any>(this.host + '/operator/auth', {token, operatorId: opratorId},
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                })
            }).pipe(map(userInfo => {
            return userInfo;
        }));
    }


    getMenu() {
        return this.http.get<any>(this.host + '/menu',
            {
                headers: this.getHeaders()
            }).pipe(map(data => {
            return data;
        }));
    }

    getRoundmarkets(eventId) {
        return this.http.get<any>(this.host + '/api/exchange/odds/roundmarkets/' + eventId,
            {
                headers: this.getHeaders()
            }).pipe(map(data => {
            return data;
        }));
    }

    getMarketBetsUser(marketId, userId) {
        return this.http.get<any>(this.host + '/order/settledbets/' + marketId + '/' + userId,
            {
                headers: this.getHeaders()
            }).pipe(map(data => {
            return data;
        }));
    }

    getBalance() {
        return this.http.get(`${this.host}/clients/wallInfo`,
            {
                headers: this.getHeaders()
            }).pipe(map((data: WalletInfoResponse) => {
            return data;
        }));
    }

    getEventTypes(eventId) {

        return this.http.get<any>(this.host + '/menu/home/' + eventId,
            {
                headers: this.getHeaders()
            }).pipe(map(eventTypes => {
            return eventTypes;
        }));
    }

    getDynamicStakes() {
        return this.http.get<any>(this.host + '/userstack',
            {
                headers: this.getHeaders()
            }).pipe(map(stakes => {
            return stakes;
        }));
    }

    saveDynamicStakes(newStakes) {
        return this.http.post<any>(this.host + '/userstack/updateStack', newStakes,
            {
                headers: this.getHeaders()
            }).pipe(map(stakes => {
            return stakes;
        }));
    }

    saveMobileStakes(newStakes) {
        return this.http.post<any>(this.host + '/userstack/updateBetSetting', newStakes,
            {
                headers: this.getHeaders()
            }).pipe(map(stakes => {
            return stakes;
        }));
    }


    getPnlTable() {
        const data = {
            userId: JSON.parse(sessionStorage.getItem('currentUser')).userId,
            from: '2019-05-1',
            to: '2019-05-30'
        };
        return this.http.post<any>(this.host + '/profitloss/account/pnl', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }).pipe(map(pnl => {
            return pnl;
        }));
    }

    setMarketVoid(marketId) {
        return this.http.get<any>(this.host + '/api/exchange/odds/voidMarket/' + marketId,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(map(result => {
            return result;
        }));
    }

    getStreamData(gameId) {
        return this.http.get<any>(this.host + '/stream/' + gameId,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(map(result => {
            return result;
        }));
    }

    setTimestampToLocalStorage() { // set date in unix epoch time to localstorage
        const date = new Date();
        const timestamp = date.getTime() + 86400000; // add 24 hours
        localStorage.setItem('timestamp', timestamp.toString());
    }

    getTimestampFromLocalStorage() { // get date in unix epoch time from localstorage
        return localStorage.getItem('timestamp');
    }

    minMaxValues: { [key: number]: MinMaxResult } = JSON.parse(localStorage.getItem('minMaxValues')) || {};

    getMinMaxValues(operatorId: string, gameId: number, currency: string) {
        try {
            const URL = this.host + '/operator/gamebetlimits?operatorId=' + operatorId + '&gameId=' + gameId + '&currencyType=' + currency;
            return this.http.get<BetLimitResponse>(URL, { headers: this.getHeaders() }
            ).pipe(map(minMaxValues => {
                if (minMaxValues && minMaxValues.result) {
                    this.minMaxValues[gameId] = minMaxValues.result;
                    this.setTimestampToLocalStorage();
                    localStorage.setItem('minMaxValues', JSON.stringify(this.minMaxValues));
                }
                return minMaxValues.result;
            }
            ));
        } catch (e) {
            console.error(e);
        }
    }
}
