import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {IntranetService} from '../../services/intranet.service';

@Component({
    selector: 'app-result-popup',
    templateUrl: './result-popup.component.html',
    styleUrls: ['./result-popup.component.scss'],
})
export class ResultPopupComponent implements OnInit, OnChanges {

    constructor(
        private modalService: ModalService,
        private el: ElementRef,
        private changeDetector: ChangeDetectorRef,
        private intranetService: IntranetService,
    ) {
        this.element = el.nativeElement;
    }

    @Input() id: string;
    @Output() cancelModal = new EventEmitter();
    @Input() marketData: any;
    @Input() rname: any;
    showLoader: boolean;
    modal = document.getElementById('myModal');
    private element: any;
    @Input() player1Status: boolean;
    @Input() player2Status: boolean;
    @Input() player3Status: boolean;
    @Input() player4Status: boolean;
    @Input() player5Status: boolean;
    @Input() player6Status: boolean;

    resultPopupVisible = false;
    roundResultsBettingData = {};
    roundId: string;
    gameId: string;

    TEEN_PATTI = {id: '56767', name: 'Live Teenpatti', type: 'teenpatti', subType: 'teenpatti'};
    ANDER_BAHAR = {id: '87564', name: 'Andar Bahar', type: 'andarbahar', subType: 'andarbahar'};
    POKER = {id: '67564', name: 'Poker', type: 'poker', subType: 'oneDay'};
    POKER_20_20 = {id: '67567', name: 'Poker 20-20', type: 'poker_20_20', subType: 'poker_20_20'};
    BOLLYWOOD_CASINO = {id: '67570', rngId: '67571', name: 'Bollywood Casino', type: 'bollywood_casino', subType: 'bollywood_casino'};
    CASINO_METER = {id: '67575', rngId: '67576', name: 'Casino Meter', type: 'casino_meter', subType: 'casino_meter'};
    CASINO_WAR = {id: '67580', rngId: '67581', name: 'Casino War', type: 'casino_war', subType: 'casino_war'};
    BACCARAT = {id: '92038', rngId: '92039', name: 'Baccarat', type: 'Baccarat', subType: 'Baccarat'};
    SIX_POKER = {id: '67565', name: 'Six Player Poker', type: 'six_poker', subType: 'oneDay'};
    DICE = {id: '98789', name: '7 Up & Down', type: 'Dice', subType: 'Dice'};
    ROULLET = {id: '98788', name: 'Roulette', type: 'Roulette', subType: 'Roulette'};
    SICBO = {id: '98566', rngId: '98565', name: 'Sicbo', type: 'sicbo', subType: 'sicbo'};
    DREAM_CATCHER = {id: '98567', name: 'Dream Catcher', type: 'dreamCatcher', subType: 'dreamCatcher'};
    CARDS_32_CASINO = {id: '56967', name: '32 cards casino', type: '32_casino', subType: '32_casino'};
    DRAGON_TIGER = {id: '98790', name: 'Dragon Tiger', type: 'dragon_tiger', subType: 'dragon_tiger'};
    WORLI_MATKA = {id: '92037', name: 'WORLI MATKA', type: 'WORLI_MATKA', subType: 'WORLI_MATKA'};
    HI_LOW = {id: '56968', name: 'Hi Low', type: 'hi_low', subType: 'hi_low'};
    TRIPLE_CARD_POKER = {id: '67585', rngId: '67586', name: 'Triple Card Poker', type: 'triple_card_poker', subType: 'triple_card_poker'};
    CASINO_HOLDEM_POKER = {id: '67590', rngId: '67591', name: 'Casino Hold\'em Poker', type: 'casino_holdem_poker', subType: 'casino_holdem_poker'};
    MUFLIS_TEENPATTI = {id: '67600', rngId: '67601', name: 'Muflis Teenpatti', type: 'muflis', subType: 'muflis'};
    TRIO_GAME = {id: '67610', rngId: '67611', name: 'Trio', type: 'trio', subType: 'trio'};
    QUEEN = {id: '67620', rngId: '67621', name: 'Queen', type: 'queen', subType: 'queen'};
    TEENPATTI_TEST = {id: '67630', rngId: '67631', name: 'Teenpatti Test', type: 'teenpatti', subType: 'test'};
    TEENPATTI_OPEN = {id: '67640', rngId: '67641', name: 'Teenpatti Open', type: 'teenpatti', subType: 'open'};
    KBC = {id: '67650', rngId: '67651', name: 'K.B.C (Kaun Banega Crorepati)', type: 'kbc', subType: 'kbc'};
    TWO_CARD_TP = {id: '67660', rngId: '67661', name: '2 Cards Teenpatti', type: '2_card_teenpatti', subType: '2_card_teenpatti'};
    THREE_CARDS_JUDGEMENT = {id: '67670', rngId: '67671', name: '3 Cards Judgement', type: 'three_card_judgement', subType: 'three_card_judgement'};
    THE_TRAP = {id: '67680', rngId: '67681', name: 'The Trap', type: 'trap', subType: 'trap'};
    _29_CARD_BACCARAT = {id: '67690', rngId: '67691', name: '29 Card Baccarat', type: '29_card_baccarat', subType: '29_card_baccarat'};
    RACE_TO_17 = {id: '67710', rngId: '67711', name: 'Race to 17', type: 'race17', subType: 'race17'};
    SUPER_OVER = {id: '67720', rngId: '67721', name: 'Super Over', type: 'super_over', subType: 'super_over'};

    TEEN_PATTI_T20 = {
        id: '56768',
        name: 'Teenpatti T20',
        type: 'teenpatti',
        subType: {
            T20: 'T20'
        },
        availableTypes: {PLAIN: 'plain', PLUS: 'plus'}
    };
    AMAR_AKBAR_ANTHONY = {id: '98791', name: 'Amar Akbar Anthony', type: 'amar_akbar_anthony', subType: 'amar_akbar_anthony'};
    RACE_20_20 = {id: '90100', rngId: '90101', name: 'Race 20 20', type: 'race_20_20', subType: 'race_20_20'};
    RACE_20_20_VIRTUAL = {id: '90101', name: 'Race 20 20 Virtual', type: 'race_20_20', subType: 'race_20_20'};
    BACCARAT_VIRTUAL = {id: '92039', name: 'Baccarat Virtual', type: 'Baccarat', subType: 'Baccarat'};
    BOLLYWOOD_CASINO_VIRTUAL = {id: '67571', name: 'Bollywood Casino Virtual', type: 'bollywood_casino', subType: 'bollywood_casino'};
    CASINO_WAR_VIRTUAL = {id: '67581', name: 'Casino War Virtual', type: 'casino_war', subType: 'casino_war'};
    TEEN_PATTI_TEST_VIRTUAL = {id: '67631', name: 'Teenpatti Test Virtual', type: 'teenpatti_test', subType: 'teenpatti_test'};
    TEEN_PATTI_MUFLIS_VIRTUAL = {id: '67601', name: 'Muflis Teenpatti Virtual', type: 'teenpatti_muflis', subType: 'teenpatti_muflis'};
    TRIO_VIRTUAL = {id: '67611', name: 'Trio Virtual', type: 'trio', subType: 'trio'};
    POKER_20_20_VIRTUAL = {id: '67568', name: 'Poker 20-20 Virtual', type: 'poker_20_20', subType: 'poker_20_20'};
    CASINO_METER_VIRTUAL = {id: '67576', name: 'Casino Meter Virtual', type: 'casino_meter', subType: 'casino_meter'};
    QUEEN_VIRTUAL = {id: '67621', name: 'Queen Virtual', type: 'queen', subType: 'queen'};
    TEEN_PATTI_OPEN_VIRTUAL = {id: '67641', name: 'Teenpatti Open Virtual', type: 'teenpatti-open-virtual', subType: 'teenpatti-open-virtual'};
    TRAP_VIRTUAL = {id: '67681', name: 'The Trap Virtual', type: 'the_trap', subType: 'trap'};
    TEEN_PATTI_2_CARD_VIRTUAL = {id: '67661', name: '2 Card Teenpatti Virtual', type: 'teenpatti_2_card', subType: 'teenpatti_2_card'};
    RACE_TO_17_VIRTUAL = {id: '67711', name: 'Race to 17 Virtual', type: 'race_to_17', subType: 'race_to_17'};
    BACCARAT_29_CARD_VIRTUAL = {id: '67691', name: '29 Card Baccarat Virtual', type: '29_Card_Baccarat', subType: '29_Card_Baccarat'};
    THREE_CARDS_JUDGEMENT_VIRTUAL = {id: '67671', name: '3 Cards Judgement Virtual', type: 'three_card_judgement', subType: 'three_card_judgement'};
    SUPER_OVER_VIRTUAL = {id: '67721', name: 'Super Over Virtual', type: 'super_over', subType: 'super_over'};
    SICBO_VIRTUAL = {id: '98565', name: 'Super Sic Bo Virtual', type: 'sicbo', subType: 'sicbo'};
    DREAM_CATCHER_VIRTUAL = {id: '98568', name: 'Dream Catcher Virtual', type: 'dreamCatcher', subType: 'dreamCatcher'};

    RED = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
    BLACK = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];

    private scrolling = false;

    ngOnInit(): void {
        this.showLoader = true;
        const modal = this;
        // ensure id attribute exists
        if (!this.id) {
            // console.error('modal must have an id');
            return;
        }
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        this.player1Status = false;
        this.player2Status = false;
        this.player3Status = false;
        this.player4Status = false;
        this.player5Status = false;
        this.player6Status = false;
        this.roundId = this.marketData.marketId.roundId;
        this.gameId = this.marketData.marketId.gameId;
        if (this.gameId === ('67580' || '67581')) {
            this.getCasinoWarWinners(this.marketData);
        }
        if (this.gameId === ('67640' || '67641')) {
            this.getRoundResults(this.roundId);
        }

        // close modal on background click
        this.element.addEventListener('click', function(e: any) {
            if (e.target.className === 'jw-modal') {
                modal.close();
            }
        });
        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    onClickCancel() {
        this.showLoader = false;
        this.player1Status = false;
        this.player2Status = false;
        this.player3Status = false;
        this.player4Status = false;
        this.player5Status = false;
        this.player6Status = false;
        this.cancelModal.emit('none');
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy(): void {
        this.showLoader = false;
        this.modalService.remove(this.id);
        this.element.remove();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.roundId = this.marketData.marketId.roundId;
        this.gameId = this.marketData.marketId.gameId;

        if (this.marketData.marketId.gameId === ('67580' || '67581')) {
            this.getCasinoWarWinners(this.marketData);
        }
        if (this.marketData.marketId.gameId === ('67640' || '67641')) {
            this.getRoundResults(this.roundId);
            this.roundResultsBettingData = {};
        }
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('jw-modal-open');
    }

    // close modal
    close(): void {
        this.showLoader = false;
        this.element.style.display = 'none';
        document.body.classList.remove('jw-modal-open');
    }

    getMatkaResult() {
        if (this.marketData.marketId.indexCard.length !== 3) {
            return 'BACK.png';
        } else {
            const c1 = parseInt(this.marketData.marketId.indexCard[0].slice(1));
            const c2 = parseInt(this.marketData.marketId.indexCard[1].slice(1));
            const c3 = parseInt(this.marketData.marketId.indexCard[2].slice(1));
            const mod10 = (c1 + c2 + c3) % 10;
            return 'N' + mod10 + '.png';
        }
    }

    getMarketName() {
        switch (this.marketData.marketId.gameId) {
            case this.TEEN_PATTI.id:
                return this.TEEN_PATTI.name;
            case this.TEEN_PATTI_T20.id:
                return this.TEEN_PATTI_T20.name;
            case this.ANDER_BAHAR.id:
                return this.ANDER_BAHAR.name;
            case this.POKER.id:
                return this.POKER.name;
            case this.POKER_20_20.id:
                return this.POKER_20_20.name;
            case this.BOLLYWOOD_CASINO.id:
                return this.BOLLYWOOD_CASINO.name;
            case this.CASINO_METER.id:
                return this.CASINO_METER.name;
            case this.CASINO_WAR.id:
                return this.CASINO_WAR.name;
            case this.BACCARAT.id:
                return this.BACCARAT.name;
            case this.SIX_POKER.id:
                return this.SIX_POKER.name;
            case this.DICE.id:
                return this.DICE.name;
            case this.ROULLET.id:
                return this.ROULLET.name;
            case this.SICBO.id:
                return this.SICBO.name;
            case this.DREAM_CATCHER.id:
                return this.DREAM_CATCHER.name;
            case this.CARDS_32_CASINO.id:
                return this.CARDS_32_CASINO.name;
            case this.WORLI_MATKA.id:
                return this.WORLI_MATKA.name;
            case this.HI_LOW.id:
                return this.HI_LOW.name;
            case this.DRAGON_TIGER.id:
                return this.DRAGON_TIGER.name;
            case this.AMAR_AKBAR_ANTHONY.id:
                return this.AMAR_AKBAR_ANTHONY.name;
            case this.RACE_20_20.id:
                return this.RACE_20_20.name;
            case this.TRIPLE_CARD_POKER.id:
                return this.TRIPLE_CARD_POKER.name;
            case this.CASINO_HOLDEM_POKER.id:
                return this.CASINO_HOLDEM_POKER.name;
            case this.MUFLIS_TEENPATTI.id:
                return this.MUFLIS_TEENPATTI.name;
            case this.TRIO_GAME.id:
                return this.TRIO_GAME.name;
            case this.QUEEN.id:
                return this.QUEEN.name;
            case this.TEENPATTI_TEST.id:
                return this.TEENPATTI_TEST.name;
            case this.TEENPATTI_OPEN.id:
                return this.TEENPATTI_OPEN.name;
            case this.KBC.id:
                return this.KBC.name;
            case this.TWO_CARD_TP.id:
                return this.TWO_CARD_TP.name;
            case this.THREE_CARDS_JUDGEMENT.id:
                return this.THREE_CARDS_JUDGEMENT.name;
            case this.THE_TRAP.id:
                return this.THE_TRAP.name;
            case this._29_CARD_BACCARAT.id:
                return this._29_CARD_BACCARAT.name;
            case this.RACE_TO_17.id:
                return this.RACE_TO_17.name;
            case this.SUPER_OVER.id:
                return this.SUPER_OVER.name;
            case this.RACE_20_20_VIRTUAL.id:
                return this.RACE_20_20_VIRTUAL.name;
            case this.BACCARAT_VIRTUAL.id:
                return this.BACCARAT_VIRTUAL.name;
            case this.BOLLYWOOD_CASINO_VIRTUAL.id:
                return this.BOLLYWOOD_CASINO_VIRTUAL.name;
            case this.CASINO_WAR_VIRTUAL.id:
                return this.CASINO_WAR_VIRTUAL.name;
            case this.TEEN_PATTI_TEST_VIRTUAL.id:
                return this.TEEN_PATTI_TEST_VIRTUAL.name;
            case this.POKER_20_20_VIRTUAL.id:
                return this.POKER_20_20_VIRTUAL.name;
            case this.CASINO_METER_VIRTUAL.id:
                return this.CASINO_METER_VIRTUAL.name;
            case this.TRIO_VIRTUAL.id:
                return this.TRIO_VIRTUAL.name;
            case this.QUEEN_VIRTUAL.id:
                return this.QUEEN_VIRTUAL.name;
            case this.TEEN_PATTI_MUFLIS_VIRTUAL.id:
                return this.TEEN_PATTI_MUFLIS_VIRTUAL.name;
            case this.TEEN_PATTI_OPEN_VIRTUAL.id:
                return this.TEEN_PATTI_OPEN_VIRTUAL.name;
            case this.TRAP_VIRTUAL.id:
                return this.TRAP_VIRTUAL.name;
            case this.TEEN_PATTI_2_CARD_VIRTUAL.id:
                return this.TEEN_PATTI_2_CARD_VIRTUAL.name;
            case this.RACE_TO_17_VIRTUAL.id:
                return this.RACE_TO_17_VIRTUAL.name;
            case this.BACCARAT_29_CARD_VIRTUAL.id:
                return this.BACCARAT_29_CARD_VIRTUAL.name;
            case this.THREE_CARDS_JUDGEMENT_VIRTUAL.id:
                return this.THREE_CARDS_JUDGEMENT_VIRTUAL.name;
            case this.SUPER_OVER_VIRTUAL.id:
                return this.SUPER_OVER_VIRTUAL.name;
            case this.SICBO_VIRTUAL.id:
                return this.SICBO_VIRTUAL.name;
            case this.DREAM_CATCHER_VIRTUAL.id:
                return this.DREAM_CATCHER_VIRTUAL.name;
            default:
                return this.marketData.marketId.gameType;
        }
        return this.marketData.marketId.gameType.replace(/_/g, ' ');
    }

    startScroll(andarBaharPlayer: string, scroll: number) {
        this.scrolling = true;
        this.scrollCards(andarBaharPlayer, scroll);
    }

    stopScroll() {
        this.scrolling = false;
    }

    scrollCards(andarBaharPlayer: string, scroll: number) {
        const $this = this;
        setTimeout(function() {
            const elem = document.getElementById(andarBaharPlayer);
            elem.scrollLeft += (scroll * 1);
            if ($this.scrolling) {
                $this.scrollCards(andarBaharPlayer, scroll);
            }
        }, 50);
    }

    getWinnerBy(marketData: any) {
        const winners = this.marketData.marketId.marketRunner.filter((runner) => {
            return runner.status === 'WINNER';
        });
        if (winners.length > 0) {
            return winners[0].resDesc;
        } else {
            return '';
        }
    }

    getWinnerRunnerName() {
        const winners = this.marketData.marketId.marketRunner.filter((runner) => {
            return runner.status === 'WINNER';
        });
        if (winners.length > 0) {
            return winners[0].name;
        } else {
            return '';
        }
    }

    checkColor(num, type) {
        console.log(this.RED.indexOf(num));
        // Color Red
        if (this.RED.indexOf(num) !== -1) {
          switch (type) {
            case 'hover' : {
              return '#990000';
            }
            case 'normal' : {
              return '#FF0000';
            }
            case 'transaparent' : {
              return '#FF000094';
            }
          }
        } else if (this.BLACK.indexOf(num) !== -1) {
          switch (type) {
            case 'hover' : {
              return '#000000';
            }
            case 'normal' : {
              return '#000000';
            }
            case 'transaparent' : {
              return '#00000094';
            }
          }
        } else {
          switch (type) {
            case 'hover' : {
              return '#336600';
            }
            case 'normal' : {
              return '#00CC00';
            }
            case 'transaparent' : {
              return '#00CC0094';
            }
          }
        }
      }

    getRoundResults(roundId) {
        this.intranetService.getRoundmarkets(roundId).subscribe(
            data => {
                this.roundResultsBettingData = data.result;
                this.changeDetector.detectChanges();
            },
            error => {
                console.log(error);
            }
        );
    }

    assert(condition, error) {
        if (!condition) {
            // console.trace(error);
            throw error;
        }
    }

    parseGeneralCard(str) {
        const suit = str[0];
        this.assert(suit === 'C' || suit === 'D' || suit === 'H' || suit === 'S', 'invalid suit');

        const rank = parseInt(str.slice(1));
        this.assert(rank >= 1 && rank <= 13, 'invalid rank');

        return {rank, suit};
    }

    isRunnerWinner(market, runner) {
        if (this.roundResultsBettingData && this.roundResultsBettingData[market] && this.roundResultsBettingData[market].marketRunner[runner]) {
            return this.roundResultsBettingData[market].marketRunner[runner].status === 'WINNER';
            } else {
            return false;
            }
     }
    getCasinoWarWinners(marketData: any) {
        const player1Card = this.parseGeneralCard(this.marketData.marketId.cards[0]).rank;
        const player2Card = this.parseGeneralCard(this.marketData.marketId.cards[1]).rank;
        const player3Card = this.parseGeneralCard(this.marketData.marketId.cards[2]).rank;
        const player4Card = this.parseGeneralCard(this.marketData.marketId.cards[3]).rank;
        const player5Card = this.parseGeneralCard(this.marketData.marketId.cards[4]).rank;
        const player6Card = this.parseGeneralCard(this.marketData.marketId.cards[5]).rank;
        const dealerCard = this.parseGeneralCard(this.marketData.marketId.cards[6]).rank;

        const player1Suit = this.parseGeneralCard(this.marketData.marketId.cards[0]).suit;
        const player2Suit = this.parseGeneralCard(this.marketData.marketId.cards[1]).suit;
        const player3Suit = this.parseGeneralCard(this.marketData.marketId.cards[2]).suit;
        const player4Suit = this.parseGeneralCard(this.marketData.marketId.cards[3]).suit;
        const player5Suit = this.parseGeneralCard(this.marketData.marketId.cards[4]).suit;
        const player6Suit = this.parseGeneralCard(this.marketData.marketId.cards[5]).suit;
        const dealerSuit = this.parseGeneralCard(this.marketData.marketId.cards[6]).suit;

        if (player1Card > dealerCard) {
            this.player1Status = true;
        } else if (player1Card < dealerCard) {
            this.player1Status = false;
        } else if (player1Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player1Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player1Status = false;
            }
            if (dealerRank > playerRank) {
                this.player1Status = true;
            }
        }

        if (player2Card > dealerCard) {
            this.player2Status = true;
        } else if (player2Card < dealerCard) {
            this.player2Status = false;
        } else if (player2Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player2Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player2Status = false;
            }
            if (dealerRank > playerRank) {
                this.player2Status = true;
            }
        }

        if (player3Card > dealerCard) {
            this.player3Status = true;
        } else if (player3Card < dealerCard) {
            this.player3Status = false;
        } else if (player3Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player3Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player3Status = false;
            }
            if (dealerRank > playerRank) {
                this.player3Status = true;
            }
        }

        if (player4Card > dealerCard) {
            this.player4Status = true;
        } else if (player4Card < dealerCard) {
            this.player4Status = false;
        } else if (player4Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player4Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player4Status = false;
            }
            if (dealerRank > playerRank) {
                this.player4Status = true;
            }
        }

        if (player5Card > dealerCard) {
            this.player5Status = true;
        } else if (player5Card < dealerCard) {
            this.player5Status = false;
        } else if (player5Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player5Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player5Status = false;
            }
            if (dealerRank > playerRank) {
                this.player5Status = true;
            }
        }

        if (player6Card > dealerCard) {
            this.player6Status = true;
        } else if (player6Card < dealerCard) {
            this.player6Status = false;
        } else if (player6Card === dealerCard) {
            const order = ['D', 'C', 'H', 'S'];

            const dealerRank = order.indexOf(player6Suit);
            const playerRank = order.indexOf(dealerSuit);

            if (dealerRank < playerRank) {
                this.player6Status = false;
            }
            if (dealerRank > playerRank) {
                this.player6Status = true;
            }
        }
    }

    getCardNumber(card) {
        return card.substr(1, card.legngt);
    }

    getSumOfSuperOverCards(cards) {
        let sum = 0;
        for (const card of cards) {
            // tslint:disable-next-line:radix
            const rank = parseInt(this.getCardNumber(card));
            if (rank < 10) {
                sum += rank;
            }
        }
        return sum;
    }

    getSuperOverWickets(cards) {
        let num = 0;
        for (const card of cards) {
            // tslint:disable-next-line:radix
            const rank = parseInt(this.getCardNumber(card));
            if (rank === 13) {
                num++;
            }
        }
        return num;
    }

    checkColorDreamCatcher(num) {
        console.log(this.RED.indexOf(num));
        // Color Red
        if (num === '1') {
            return '#f6d707';
        } else if (num === '2') {
            return '#0f43ff';
        } else if (num === '5') {
            return '#c52ffc';
        } else if (num === '10') {
            return '#39e709';
        } else if (num === '20') {
            return '#fc6d0f';
        } else if (num === '40') {
            return '#f5121a';
        } else if (num === '2x') {
            return '#000000';
        } else if (num === '7x') {
            return '#000000';
        }
    }
    }

